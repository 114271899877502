import React, {useEffect, useRef, useState} from 'react';
import {
    downloadDocumentByBlobIdAndApplicationType,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig, sourceFormatToTargetFormatToString, stringToDayjs_yyyy_MM_dd, utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import {Card, Col, FormInstance, Modal, Row, Spin, Tooltip} from 'antd';
import {CompanySelection, descriptionFilter, PartnerSelection,} from '../../InfoPortalDropdowns';
import {RuleSet} from '../../../../e-cap/util/RuleResultCache';
import {IntlShape} from 'react-intl/src/types';
import {PortalUser} from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat, dateComparatorUsingUserPreferences, getDateFormatOrDefault,
    getDateFormatWithNoDate, numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {GRSCDate} from "../../InfoDateSelection";
import {retrieveCR1Report} from "../../../services/ReportSearch";
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";
import dayjs, {Dayjs} from "dayjs";
import {GridApi} from "ag-grid-community";
import {generateConfirmationOfArrivalPDF} from "../../../services/PDFGenerationService";
import {Buffer} from "buffer";

import { saveAs } from 'save-as';
import {FileOutlined} from "@ant-design/icons";
import {getCurrentLocale} from "../../../../../utils";
import {CORAPI_findCR1ReportResults} from "../../../graphql/__generated__/CORAPI";

function validatator(_form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'grScDate') {
                result.required = true;
            }

            return result;
        },
    };
    return new Validator(validator);
}

const ConfirmationOfArrivalSimpleSearch = ({
                                               currentUser,
                                               intl,
                                               reportEntitlement,
                                               distinctEntitlements,
                                               initialization,
                                               form,
                                               initialValuesCache,
                                               rerenderDependentFields
                                           }: InfoPortalSearchProperties) => {
    const vertical = useRef<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    const dateDisabler = (current: Dayjs) => {
        const lastMonth = dayjs().subtract(1, 'month').endOf('month');
        return current.isAfter(lastMonth);
    }

    return <>

        <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
            <Row gutter={26}>
                <Col span={12}>
                    <CompanySelection
                        selectionMode={DropDownSelectionMode.SINGLE}
                        labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={true}
                        currentUser={currentUser}
                        initialValue={initialValuesCache?.getValue("company")}
                        intl={intl}
                        validator={validator}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        onSelectionChange={companySelectionChange}
                        customFilter={item => ['A1', 'UH'].find(companyCode => companyCode === item.companyCode) !== undefined}
                        filterOptions={descriptionFilter}
                        companyEntitlement={reportEntitlement}/>
                    <PartnerSelection
                        selectionMode={DropDownSelectionMode.SINGLE}
                        partnerEntitlement={reportEntitlement}
                        currentUser={currentUser} intl={intl}
                        initialValue={initialValuesCache?.getValue("partner")}
                        customLabel={intl.formatMessage({id: 'partner-number-contractual-partner'})}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        validator={validator}
                        labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={true}
                        companyIds={initialValuesCache?.getValue("company")}
                        externalStateUpdater={partnerSelectionUpdate}
                        filterOptions={descriptionFilter}/>
                </Col>
                <Col span={12}>
                    <GRSCDate
                        validator={validator}
                        dateFormatter={getDateFormatWithNoDate(currentUser)}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialValue={initialValuesCache?.getValue("grScDate", stringToDayjs_yyyy_MM_dd)}
                        dateDisabler={dateDisabler}
                        form={form}/>
                </Col>

            </Row>
        </Card>

    </>;
};

function generateFileName(companyCode: string, partnerNumber: string, partnerLl: string, yearMonth: string) {
    const currentLocale = getCurrentLocale();

    if (currentLocale === 'de') {
        return `GB_${companyCode}_${partnerNumber}_${partnerLl}_${yearMonth.substring(0,4)}-${yearMonth.substring(4)}.pdf`;
    }

    return `COA_${companyCode}_${partnerNumber}_${partnerLl}_${yearMonth.substring(0,4)}-${yearMonth.substring(4)}.pdf`;
}

export function DownloadPDF(datum:CORAPI_findCR1ReportResults, intl:IntlShape) {
    const [loading, setLoading] = useState<boolean>(false);

    function downloadBlob(blobId: number, application:string) {
        setLoading(true);
        Promise.resolve(downloadDocumentByBlobIdAndApplicationType(blobId, application))
            .then((data) => {
                const mimeType = data.value.mimeType;
                const decodedData = Buffer.from(data.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob, generateFileName(datum.companyCode, datum.partnerNumber, datum.partnerLl, datum.yearMonth));
            }).catch(error => {
            console.error(error);
            Modal.warning({
                title: intl.formatMessage({id: "Download Error",}),
                content: intl.formatMessage({
                    id: "rejection-letter-download-failed",
                })
            });
        }).finally(() => setLoading(false));
    }

    if (datum.blobId) {
        return <Spin data-testid={loading? `cr1_downloading_${datum.blobId}`:`cr1_finished_downloading_${datum.blobId}`} size={"large"} spinning={loading}>
            <Tooltip
                style={{textTransform:"capitalize"}}
                title={`${intl.formatMessage({id:'welcome-download-button'})}`}>
                <FileOutlined data-testid={`cr1_pdf_${datum.blobId}`} style={{cursor: "pointer"}} onClick={_ => downloadBlob(datum.blobId, "ip")}/>
            </Tooltip>
        </Spin>
    }

    return <></>
}

export class ConfirmationOfArrivalAgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape, user: PortalUser): InfoPortalColumnDef[] {
        return [
            {
                field: "documentCreated",
                headerName: intl.formatMessage({"id": "document-created"}),
                valueGetter: (params) => params.data.documentCreated ? intl.formatMessage({"id": "ecap-capture-bmw-retail-outlet-choice-yes"}) : intl.formatMessage({"id": "ecap-capture-bmw-retail-outlet-choice-no"})
            },
            {
                field: "blobId",
                headerName: intl.formatMessage({"id": "pdf-file"}),
                cellRenderer: (params: any) => DownloadPDF(params.data, intl),
                exportFormatter: params => generateFileName(params.data.companyCode, params.data.partnerNumber, params.data.partnerLl, params.data.yearMonth)
            },
            {
                field: "documentCreationDate",
                headerName: intl.formatMessage({"id": "creation-date"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentCreationDate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {field: "companyName", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerNumber", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "partnerName", headerName: intl.formatMessage({"id": "grid-heading-partner-name"})},
            {
                field: "lfStreet",
                headerName: intl.formatMessage({"id": "grid-heading-partner-address-street"})
            },
            {
                field: "partnerAddress",
                headerName: intl.formatMessage({"id": "grid-heading-partner-address-country-postal-code-city"}),
                valueGetter: (params) => {
                    return `${params.data.lfCountry}, ${params.data.lfPostalCode}, ${params.data.lfCity}`;
                },
                tooltipValueGetter: (params) => {
                    return `${params.data.lfCountry}, ${params.data.lfPostalCode}, ${params.data.lfCity}`;
                },
            },
            {field: "vatNr", headerName: intl.formatMessage({"id": "grid-heading-vat-id"})},
            {field: "partnerLl", headerName: intl.formatMessage({"id": "grid-heading-goods-supplier-number"})},
            {field: "partnerLlName", headerName: intl.formatMessage({"id": "grid-heading-name-of-goods-supplier"})},
            {
                field: "llStreet",
                headerName: intl.formatMessage({"id": "grid-heading-address-of-goods-supplier-street"})
            },
            {
                field: "supplierAddress",
                headerName: intl.formatMessage({"id": "grid-heading-address-of-goods-supplier-country-postal-code-city"}),
                valueGetter: (params)=> this.getCountryPostalCodeAndCity(params),
                tooltipValueGetter: (params)=> this.getCountryPostalCodeAndCity(params)
            },
            {field: "llCountry", headerName: intl.formatMessage({"id": "grid-heading-country-of-goods-supplier"})}
        ];
    }

    getShortViewColumns(): string[] {
        return [
            "documentCreated",
            "blobId",
            "documentCreationDate",
            "companyName",
            "partnerNumber",
            "vatNr",
            "partnerLl",
            "partnerLlName",
            "llCountry"
        ];
    }

    getLineItemColumnDefinitions(intl: IntlShape, user: PortalUser): InfoPortalColumnDef[] {
        return [
            {
                field: "poNumber",
                headerName: intl.formatMessage({"id": "purchase-order-number"})
            },
            {
                field: "material",
                headerName: intl.formatMessage({"id": "grid-heading-material"})
            },
            {
                field: "materialText",
                headerName: intl.formatMessage({"id": "grid-heading-materialText"})
            },
            {
                field: "deliveryNote",
                headerName: intl.formatMessage({"id": "grid-heading-delivery-note"})
            },
            {
                field: "deliveryNoteDate",
                headerName: intl.formatMessage({"id": "document-overview-grid-header-DocDate"}),
                valueGetter: params => sourceFormatToTargetFormatToString(params.data.deliveryNoteDate, '20[2-3][0-9][0-1][0-9][0-3][0-9]', 'YYYYMMDD', getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter: 'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "grScItem",
                headerName: intl.formatMessage({"id": "grid-heading-grscitem"})
            },
            {
                field: "materialDoc",
                headerName: intl.formatMessage({"id": "bmw-gr-sc-document-number"})
            },
            {
                field: "grScDate",
                headerName: intl.formatMessage({"id": "grid-heading-grscDate"}),
                valueGetter: params => sourceFormatToTargetFormatToString(params.data.grScDate, '20[2-3][0-9][0-1][0-9][0-3][0-9]', 'YYYYMMDD', getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter: 'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "gjahr",
                headerName: intl.formatMessage({"id": "grid-heading-grsc-doc-number-year"})
            },
            {
                field: "grQty",
                headerName: intl.formatMessage({"id": "grid-heading-po-flip-quantity"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user, params.data.grQty, 3),
                filter: "agTextColumnFilter",
                filterParams: filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator: numericComparatorUsingUserPreferences(user)
            },
            {
                field: "grUnit",
                headerName: intl.formatMessage({"id": "grid-heading-grunit"})
            },
            {
                field: "plant",
                headerName: intl.formatMessage({"id": "grid-heading-plant"})
            },
            {
                field: "plantAddress",
                headerName: intl.formatMessage({"id": "grid-heading-location-of-goods-receipt"})
            },
            {
                field: "receiptMessage",
                headerName: intl.formatMessage({"id": "grid-heading-person-who-posted-the-goods-receipt"}),
                valueGetter: (params) => this.goodsReceiptPoster(params, intl),
                exportFormatter: (params) => this.goodsReceiptPoster(params, intl),
                tooltipValueGetter: (params) => this.goodsReceiptPoster(params, intl),
            },
            {field: "logsys", headerName: intl.formatMessage({"id": "grid-heading-bmw-system"})},
        ];
    }

    private goodsReceiptPoster(params:any, intl:IntlShape){
        const nameOfBoardMember = params.data.boardMemberName || 'Unknown';
        if (params.data.companyName && params.data.companyName.includes('A1')) {

            return intl.formatMessage({"id": "confirmation-of-arrival-goods-acceptance-msg-A1"}).replace("_person_name_", nameOfBoardMember);
        }

        return intl.formatMessage({"id": "confirmation-of-arrival-goods-acceptance-msg-rest"});
    }

    private getCountryPostalCodeAndCity(params:any) {
        return `${params.data.llCountry}, ${params.data.llPostalCode}, ${params.data.llCity}`
    }
}

export class CR1Report implements InfoPortalReport {
    getEntitlement(): string {
        return "display_coa@reports";
    }

    private _config = new ConfirmationOfArrivalAgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form: FormInstance, _currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
        async function generatePDFDocument(rows:any[], docGridApi:GridApi) {
            const booleanPromise = await generateConfirmationOfArrivalPDF(rows);
            docGridApi.refreshCells();
            return booleanPromise;
        }

        return retrieveCR1Report(form).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("CR1", this._config), result, distinctEntitlements, generatePDFDocument));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch(_: InfoPortalSearchProperties): JSX.Element {
        return undefined;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <ConfirmationOfArrivalSimpleSearch initialization={initialization}
                                                  currentUser={currentUser}
                                                  intl={intl}
                                                  form={form}
                                                  distinctEntitlements={distinctEntitlements}
                                                  rerenderDependentFields={rerenderDependentFields}
                                                  initialValuesCache={initialValuesCache}
                                                  reportEntitlement={this.getEntitlement()}/>;
    }

    getReportName(): string {
        return "CR1";
    }
}